exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-consultation-js": () => import("./../../../src/pages/courses/consultation.js" /* webpackChunkName: "component---src-pages-courses-consultation-js" */),
  "component---src-pages-courses-index-js": () => import("./../../../src/pages/courses/index.js" /* webpackChunkName: "component---src-pages-courses-index-js" */),
  "component---src-pages-courses-one-one-js": () => import("./../../../src/pages/courses/oneOne.js" /* webpackChunkName: "component---src-pages-courses-one-one-js" */),
  "component---src-pages-courses-yoga-js": () => import("./../../../src/pages/courses/yoga.js" /* webpackChunkName: "component---src-pages-courses-yoga-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructors-js": () => import("./../../../src/pages/instructors.js" /* webpackChunkName: "component---src-pages-instructors-js" */)
}

